import { useAuthInfo } from '@propelauth/react'
import React, { createContext, useState, useContext, useEffect } from 'react'

const DemoContext = createContext()

export function DemoProvider({ children }) {
    const [isDemo, setIsDemo] = useState(false)
    const authInfo = useAuthInfo()
    const orgId = authInfo.orgHelper.getOrgs()[0].orgId

    useEffect(() => {
        switch (orgId) {
            // EL
            case '365e1a77-6eaf-443e-b72d-a3a7d3f304a3':
                setIsDemo(true)
                break
            // DBP
            case 'fbb6bd60-c0fe-44ad-97d5-b22517a0ccd7':
                setIsDemo(true)
                break
            // EL 2
            case '7327e8cc-c19d-40b9-b089-b38d132bfb62':
                setIsDemo(true)
                break
            // OP
            case '48f7e30d-f128-4460-a00a-8548052546ab':
                setIsDemo(true)
                break
            default:
                // Change to true if you want to simulate demo mode
                setIsDemo(false)
                break
        }
    }, [orgId])
    return (
        <DemoContext.Provider value={{ isDemo, setIsDemo }}>
            {children}
        </DemoContext.Provider>
    )
}

export function useDemo() {
    return useContext(DemoContext)
}
