import React, { useState, useEffect } from 'react'
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Box,
    Grid,
    Card,
    CardContent,
    Drawer,
    Chip,
    IconButton,
    TextareaAutosize,
    TextField,
    Tooltip,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'


const GetSources = ({
    // selectedText,
    getSources,
    documentId,
    getOrgAccessToken,
    setParentLoading,
    isExpanded,
    onExpand,
    sourceResults,
    setSourceResults,
}) => {
    const [error, setError] = useState(null)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [selectedSource, setSelectedSource] = useState(null)
    const [expanded, setExpanded] = useState(isExpanded)
    
    const [selectedText, setSelectedText] = useState('')
    let getSourcesSelectedText = '';


    var connector = "";
    useEffect(() => {
        setExpanded(isExpanded)
    }, [isExpanded])

    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded)
        if (isExpanded) {
            onExpand()
        }
    }
    // const handleSelectionText = async () => {

    //     var docEditor = window.DocEditor.instances['docxEditor'];
    //           connector = docEditor.createConnector();
    //           connector.executeMethod("GetSelectedText", [], function (selectedText) {
    //             console.log("Selected Text: ", selectedText);
    //             setSelectedText(selectedText)
    //           }
    //           );
    // }


    // const handleGetSources = async () => {
        

    //     setError(null)
    //     setParentLoading(true)

    //     await handleSelectionText();

    //     if (selectedText === '') {
    //         setError('Please select text from the document first.')
    //         setParentLoading(false)
    //         return
    //     }

    //     try {
            
    //         const tok = await getOrgAccessToken()
    //         const sources = await getSources(
    //             setParentLoading,
    //             setError,
    //             documentId,
    //             selectedText,
    //             '', // section_name is empty as per the provided function
    //             tok
    //         )
    //         if (sources) {
    //             setSourceResults(sources)
    //         }

            
    //     } catch (error) {
    //         console.error('Error getting sources:', error)
    //         setError('Failed to retrieve sources. Please try again.')
    //     } finally {
    //         setParentLoading(false)
    //     }
    // }

    const handleSelectionText = async () => {
        return new Promise((resolve, reject) => {
            var docEditor = window.DocEditor.instances['docxEditor'];
            connector = docEditor.createConnector();
            connector.executeMethod("GetSelectedText", [], function (selectedText) {
                console.log("Selected Text: ", selectedText);
                setSelectedText(selectedText);
                getSourcesSelectedText = selectedText;
                resolve(selectedText);
            });
        });
    };
    
    const handleGetSources = async () => {
        setError(null);
        setParentLoading(true);
    
        try {
            await handleSelectionText();
    
            if (getSourcesSelectedText === '') {
                console.log('error in selected text');
                setParentLoading(false);
                alert('Please select text from the document first.');
                setError('Please select text from the document first.');

                return;
            }

    
            const tok = await getOrgAccessToken();
            const sources = await getSources(
                setParentLoading,
                setError,
                documentId,
                getSourcesSelectedText,
                '', // section_name is empty as per the provided function
                tok
            );
    
            if (sources) {
                setSourceResults(sources);
            }
        } catch (error) {
            console.error('Error getting sources:', error);
            setError('Failed to retrieve sources. Please try again.');
        } finally {
            setParentLoading(false);
            getSourcesSelectedText = ""
        }
    };

    const formatSourceTitle = (source) => {
        return source.split('/').pop()
    }

    const handleSourceClick = (source) => {
        setSelectedSource(source)
        setDrawerOpen(true)
    }

    const renderSourceCards = () => (
        <Grid container spacing={1}>
            {sourceResults.map((source, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card
                        onClick={() => handleSourceClick(source)}
                        sx={{
                            cursor: 'pointer',
                            '&:hover': { boxShadow: 3 },
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Tooltip
                            title={formatSourceTitle(source.source)}
                            placement="top"
                        >
                            <CardContent sx={{ flexGrow: 1, p: 1 }}>
                                <Typography
                                    variant="subtitle2"
                                    component="div"
                                    noWrap
                                >
                                    {formatSourceTitle(source.source)}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: 'vertical',
                                    }}
                                >
                                    {source.text}
                                </Typography>
                            </CardContent>
                        </Tooltip>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )

    const renderSourceDrawer = () => (
        <>
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                <Box sx={{ width: '35vw', p: 2 }}>
                    {selectedSource && (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mb: 2,
                                }}
                            >
                                <Typography variant="h6">
                                    {formatSourceTitle(selectedSource.source)}
                                </Typography>
                                <IconButton
                                    onClick={() => setDrawerOpen(false)}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Chip
                                label={`Page ${selectedSource.page_number}`}
                                sx={{ mb: 2 }}
                            />
                            <Box>
                                <Typography variant="subtitle2" gutterBottom>
                                    Snippet:
                                </Typography>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={20}
                                    value={selectedSource.text}
                                    variant="outlined"
                                    sx={{
                                        // width: '100%',
                                        // minHeight: '200px',
                                        // maxHeight: '50vh',
                                        // overflowY: 'auto',
                                        // // padding: '8px',
                                        marginBottom: '16px',
                                        // resize: 'vertical',
                                    }}
                                    slotProps={{
                                        input: {
                                            readOnly: true,
                                        },
                                    }}
                                />
                            </Box>
                            <Button
                                variant="contained"
                                href={selectedSource.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Go to Source
                            </Button>
                        </>
                    )}
                </Box>
            </Drawer>
        </>
    )

    return (
        <>
            <Accordion expanded={expanded} onChange={handleAccordionChange}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Get Sources</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2" gutterBottom>
                        Select text in the document and click "Get Sources" to
                        retrieve source information.
                    </Typography>
                    <Button
                        onClick={ handleGetSources}
                        variant="contained"
                        color="primary"
                        disabled={false}
                    >
                        Get Sources
                    </Button>
                    <Tooltip
                        title="These sources are only chosen based on the initial draft. Selections with updated sources may not be reflected here."
                        arrow
                    >
                        <IconButton
                            size="small"
                            sx={{ ml: 1, mt: 0.25, p: 0 }}
                        >
                            <InfoIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    {error && (
                        console.log('error', error),
                        <Typography color="error" sx={{ mt: 2 }}>
                            {error}
                        </Typography>
                    )}
                    {sourceResults.length > 0 && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Source Results:
                            </Typography>
                            {renderSourceCards()}
                        </Box>
                    )}
                </AccordionDetails>
            </Accordion>
            {renderSourceDrawer()}
        </>
    )
}

export default GetSources
