import React, {
    useState,
    useRef,
    forwardRef,
    useImperativeHandle,
    useEffect,
} from 'react'
import { DocumentEditor } from '@onlyoffice/document-editor-react'
import { saveFile } from '../../utils/utils'
import save from '../../icons/Save.svg'
import { useAuthInfo } from '@propelauth/react'
import { Box, Container, Skeleton } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import { getJWTToken } from '../../utils/utils'

// const OnlyOfficeViewerComponent = forwardRef(({ initialFile, userId }, ref) => {
const OnlyOfficeViewerComponent = ({ initialFile, userId, onLoaded, documentName, documentId, documentKey }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [URL, setURL] = useState(true)
    const [token, setToken] = useState('');


    const [selectedText, setSelectedText] = useState('')
    const [pageNumber, setPageNumber] = useState(null)

    const authInfo = useAuthInfo()
    const orgs = authInfo.orgHelper.getOrgs()

    const truncatedDocumentName = documentName.split('/')[1]

    const getOrgAccessToken = async (orgId) => {
        return await authInfo.tokens.getAccessTokenForOrg(orgId)
    }

    function onLoadComponentError(errorCode, errorDescription) {
        console.log(errorCode, errorDescription)
    }

    useEffect(() => {
        setURL(initialFile)
        setIsLoading(false)
    }, [initialFile])

    const key = documentKey

    console.log('key', key)

    useEffect(() => {
        const fetchToken = async () => {

            console.log('callbackUrl', `${process.env.REACT_APP_API_URL_BASE}/api/saveDocument`)
            try {
                const payload = {
                    document: {
                        fileType: 'docx',
                        key: documentId,
                        title: truncatedDocumentName,
                        url: initialFile,
                    },
                    documentType: 'word',
                    editorConfig: {
                        callbackUrl: `https://artos-general-functions-staging.azurewebsites.net/api/saveDocument?documentName=${documentName}`,
                    },
                };

                const orgAccessToken = await getOrgAccessToken(); // Assume this function is defined elsewhere
                const data = await getJWTToken(orgAccessToken, payload);
                setToken(data.token);
            } catch (error) {
                console.error('Error fetching JWT:', error);
            }
        };

        if (initialFile) {
            fetchToken();
        }
    }, [initialFile]);

    // function onDocumentReady(event) {
    //     console.log(event)
    //     console.log('Document is loaded')
    // }


    var connector = "";

    var onDocumentReady = function (event) {

  
        var docEditor = window.DocEditor.instances['docxEditor'];
        connector = docEditor.createConnector();
      
        console.log("window.DocEditor", docEditor);

        onLoaded()
      
      
        try { 
        
        connector.executeMethod("GetSelectedText", [], function (selectedText) {
          console.log("Selected Text: ", selectedText);
        }
        );
        } catch (error) {
          console.log("Error: ", error);
        }
      
        console.log("Document is loaded");
        // setTimeout(() => {
        //     if (window.Asc && window.Asc.plugin) {
        //         window.Asc.plugin.executeMethod("GetSelectedText", [], function(selectedText) {
        //             console.log(selectedText);
        //         });
        //     } else {
        //         console.error("Asc plugin is not available");
        //     }
        // }, 5000); // Delay to ensure plugin is loaded
      };

    console.log('initialFile', initialFile)

    const SkeletonLoader = () => (
        <Container maxWidth="md">
            <Box mb={4}>
                <Skeleton variant="rectangular" width="100%" height={60} />
            </Box>
            {[...Array(4)].map((_, index) => (
                <Box key={index} mb={4}>
                    <Skeleton variant="text" width="90%" height={20} />
                    <Skeleton variant="text" width="100%" height={20} />
                    <Skeleton variant="text" width="95%" height={20} />
                    <Skeleton variant="text" width="98%" height={20} />
                    <Skeleton variant="text" width="92%" height={20} />
                </Box>
            ))}
            <Box mt={6}>
                <Skeleton variant="rectangular" width="100%" height={40} />
            </Box>
        </Container>
    )


    return (
        <div
            className="OnlyOfficeComponent"
            style={{ height: '100%', position: 'relative' }}
        >
            {isLoading && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 1000,
                        backgroundColor: 'white',
                    }}
                >
                    <SkeletonLoader />
                </div>
            )}
            {/* <UpdatedEditor /> */}
            {initialFile && !isLoading && token && typeof URL === 'string' && 
                <DocumentEditor
                id="docxEditor"
                documentServerUrl="https://ec2.artosai.com"
                config={{
                    "document": {
                        "fileType": "docx",
                        "key": key,
                        "title": truncatedDocumentName,
                        "url": initialFile,
                    },
                    "documentType": "word",
                    "editorConfig": {
                        "customization": {
                            "uiTheme": "theme-light",
                            "compactHeader": true,
                        },

                        "callbackUrl": `https://artos-general-functions-staging.azurewebsites.net/api/saveDocument?documentName=${documentName}`,

                    },
                    "token": token,
                    
                }}
                events_onDocumentReady={onDocumentReady}
                // events_onDocumentStateChange={onDocumentChanged}
                onLoadComponentError={onLoadComponentError}
            />
            
            }
        </div>
    )
}

export default OnlyOfficeViewerComponent
