import React, { useState } from 'react'
import AbcIcon from '@mui/icons-material/Abc'
import { generateAbbreviations, delay } from '../utils/utils'
import Modal from '@mui/material/Modal'
import { useAuthInfo } from '@propelauth/react'
import BaseSidebarTab from '../pages/DocumentPage/tabs/BaseSidebarTab'
import { useWebViewer } from '../contexts/WebViewerContext'
import Skeleton from '@mui/material/Skeleton'
import { revertVersion } from '../utils/utils'

function FormatComponent({ id, documentName, documentUrl }) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [revertModal, setRevertModal] = useState(false)
    const authInfo = useAuthInfo()
    const { webViewerInstance } = useWebViewer()

    const orgs = authInfo.orgHelper.getOrgs()

    async function getOrgAccessToken(orgId) {
        return await authInfo.tokens.getAccessTokenForOrg(orgId)
    }

    const handleAbbreviationsClick = async () => {
        setLoading(true)
        const orgAccessToken = await getOrgAccessToken(orgs[0].orgId)
        try {
            const result = await generateAbbreviations(id, orgAccessToken)
            console.log('result', result)
            setError(false)

            if (webViewerInstance) {
                const doc = webViewerInstance.Core.documentViewer
                await delay(2500)
                await doc.loadDocument(result, {
                    extension: 'docx',
                    enableOfficeEditing: true,
                })
            }
        } catch (error) {
            console.error(error)
            setError(true)
        } finally {
            setLoading(false)
        }
    }

    const downloadFile = async (fileUrl, fileName) => {
        try {
            const response = await fetch(fileUrl)
            const blob = await response.blob()
            const url = window.URL.createObjectURL(new Blob([blob]))
            const link = document.createElement('a')
            link.href = url
            const splitFileName = fileName.split('/')
            const secondPart = splitFileName[1] // Access the second part of the filename
            link.setAttribute('download', secondPart)
            document.body.appendChild(link)
            link.click()
            link.parentNode.removeChild(link)
        } catch (error) {
            console.error(error)
        }
    }

    const handleRevert = async () => {
        setLoading(true)
        console.log('revert to previous version')
        setRevertModal(false)
        const orgAccessToken = await getOrgAccessToken(orgs[0].orgId)
        await revertVersion(id, orgAccessToken)
        setLoading(false)
        
        window.location.reload()
    }

    return (
        <div>
            <BaseSidebarTab
                title="Global Settings"
                description="Access document-wide settings & features."
            >
                <button
                    onClick={handleAbbreviationsClick}
                    className="main-button"
                    style={{ width: '100%', marginTop:'4px', marginBottom:'4px' }}
                    disabled={loading}
                >
                    <AbcIcon sx={{ mr: 1 }} />
                    Generate Abbreviations
                </button>
                <button
                    onClick={async () => {
                        console.log('download file')
                        await downloadFile(documentUrl, documentName)
                    }}
                    className="main-button"
                    style={{ width: '100%', marginTop:'10px', marginBottom:'4px' }}
                    disabled={loading}
                >
                    Download File
                </button>
                <button
                    onClick={() => setRevertModal(true)}
                    className="main-button"
                    style={{ width: '100%', marginTop:'10px', marginBottom:'4px' }}
                    disabled={loading}
                >
                    Revert to Previous Version
                </button>
            </BaseSidebarTab>
            {loading && (
                <div style={{ padding: '20px' }}>
                    <Skeleton variant="text" width="100%" height={40} />
                    <Skeleton variant="rectangular" width="100%" height={100} />
                    <Skeleton variant="text" width="100%" height={40} />
                </div>
            )}

            <Modal open={revertModal} onClose={() => setRevertModal(false)}>
                <div className="modal-background">
                    <div className="modal-content">
                        <h2>
                            Arer you sure you want to revert to the previous version? 
                        </h2>
                        <button 
                        className="main-button"
                        style={{ width: '50%', marginTop:'4px', marginBottom:'4px' }}
                        onClick={() => setRevertModal(false)}>Cancel</button>
                        <button 
                        className="main-button"
                        style={{ width: '50%', marginTop:'4px', marginBottom:'4px' }}
                        onClick={() => {handleRevert()}}>Revert</button>
                    </div>
                </div>
            </Modal>

            <Modal open={error} onClose={() => setError(false)}>
                <div className="modal-background">
                    <div className="modal-content">
                        <h2>
                            There was an error generating abbreviations. Please
                            try again.
                        </h2>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default FormatComponent
