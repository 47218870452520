import React, { useEffect } from 'react'
import MainButton from './MainButton'
import '../App.css'
import { useNavigate } from 'react-router-dom'
import { useDemo } from '../contexts/DemoContext'
import { getHardCodedDoc } from '../utils/utils' // Import the new function
import { useAuthInfo } from '@propelauth/react'

const ComplexLoaderComponent = ({
    onNextClick1,
    documentType,
    orgId,
    orgAccessToken,
}) => {
    const navigate = useNavigate()
    const apiBaseUrl = process.env.REACT_APP_API_URL_BASE
    const { isDemo, setIsDemo } = useDemo()
    const authInfo = useAuthInfo()

    async function getOrgAccessToken() {
        const orgs = authInfo.orgHelper.getOrgs()
        const org = orgs[0].orgId
        const orgAccessToken = await authInfo.tokens.getAccessTokenForOrg(org)
        return orgAccessToken
    }


    // comment this out after demo
    // useEffect(() => {
    //     async function redirect() {
    //         const freshToken = await getOrgAccessToken()
    //         if (isDemo) {
    //             console.log('trying to redirect')
    //             const timer = setTimeout(async () => {
    //                 try {
    //                     const docId = await getHardCodedDoc(
    //                         documentType,
    //                         freshToken
    //                     )

    //                     console.log('docId:', docId)    

    //                     if (docId && !docId.toLowerCase().includes(' found')) {
    //                         console.log('redirecting to:', `/document/${docId}`)
    //                         navigate(`/document/${docId}`)
    //                     } else {
    //                         console.log('Error in auto-redirect:')
    //                     }
    //                 } catch (error) {
    //                     console.log('Error in auto-redirect:', error)
    //                 }
    //             }, 5000)

    //             return () => clearTimeout(timer)
    //         }
    //     }
    //     redirect()
    // }, [isDemo])

    return (
        <div className="upload-container" style={{ height: '50%' }}>
            <div className="loader-container">
                <div className="loader"></div>
                <p style={{ width: '400px' }}>
                    Drafting document, this may take a while...{' '}
                </p>
                <p style={{ width: '400px' }}>
                    Feel free to wait, otherwise Artos will send an email when
                    your document is ready.{' '}
                </p>

                <div className="button-container">
                    <MainButton
                        onClick={onNextClick1}
                        text=" Return to Home"
                        width="100%"
                        backgroundColor="#1f477d"
                        fontColor="#ffffff"
                        className="unfilled-button"
                        border="2px solid #1f477d"
                    />
                </div>
            </div>
        </div>
    )
}

export default ComplexLoaderComponent
