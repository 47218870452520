import React, { useState, useEffect } from 'react'
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Box,
    Grid,
    Card,
    CardContent,
    Drawer,
    Chip,
    IconButton,
    TextareaAutosize,
    TextField,
    Tooltip,
    Alert,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloseIcon from '@mui/icons-material/Close'
import { useDemo } from '../../../contexts/DemoContext'
import SourceSelectionModal from './SourceSelectionModal'
import { useAuthInfo } from '@propelauth/react'
import InfoIcon from '@mui/icons-material/Info'




const GetSources = ({
    // selectedText,
    handleRegenerate,
    documentId,
    getOrgAccessToken,
    setParentLoading,
    isExpanded,
    onExpand,
    // sourceResults,
    // setSourceResults,
    additionalInfo,
    setAdditionalInfo,
    originalSources,
    inputFiles,
    loading,
    newSources,
    setNewSources,
    allSources,
    setAllSources,
}) => {
    const [error, setError] = useState(null)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [selectedSource, setSelectedSource] = useState(null)
    const [expanded, setExpanded] = useState(isExpanded)
    const [isSourceModalOpen, setIsSourceModalOpen] = useState(false)
    
    // const [loading, setLoading] = useState(true)
    const [OGSources, setOGSources] = useState([])
    // const [newSources, setNewSources] = useState([])
    // const [allSources, setAllSources] = useState([])
    // const [inputFiles, setInputFiles] = useState([])

    const removeTrailingSlash = (str) => str.replace(/\/$/, '')
    

    const authInfo = useAuthInfo()
    const orgs = authInfo.orgHelper.getOrgs()

    
    const [selectedText, setSelectedText] = useState('')

    const { isDemo } = useDemo()

    useEffect(() => {
            // console.log('og sources', OGSources)
            console.log('new sources', newSources)
            setAllSources([...newSources]) // removed OG sources
        }, [OGSources, newSources])

    // useEffect(() => {
            
    
    //         const fetchInputFiles = async () => {
    //             try {
    //                 console.log('fetching input files')
    //                 const orgAccessToken = await getOrgAccessToken()
    //                 const files = await listAllFiles('input', orgAccessToken)
    //                 console.log('finished fetching input files')
    //                 setInputFiles(files)
    //                 setLoading(false)

    //             } catch (err) {
    //                 console.error('Error fetching input files:', err)
    //             }
    //         }
    
    //         fetchInputFiles()
    //     }, [])
    


    var connector = "";
    useEffect(() => {
        setExpanded(isExpanded)
    }, [isExpanded])

    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded)
        if (isExpanded) {
            onExpand()
        }
    }

    const handleSourceAdd = (newSourcesInput) => {
        const orgId = orgs[0].orgId
        const filteredSources = newSourcesInput
            .filter((source) => !OGSources.includes(source))
            .map((source) => `${orgId}/${removeTrailingSlash(source)}`)
            .filter((source) => !newSources.includes(source))

        setNewSources((prevSources) => [...prevSources, ...filteredSources])
    }

    const handleSourceRemove = (sourceToRemove) => {
        setNewSources((prevSources) =>
            prevSources.filter((source) => source !== sourceToRemove)
        )
    }
    // const handleSelectionText = async () => {

    //     var docEditor = window.DocEditor.instances['docxEditor'];
    //           connector = docEditor.createConnector();
    //           connector.executeMethod("GetSelectedText", [], function (selectedText) {
    //             console.log("Selected Text: ", selectedText);
    //             setSelectedText(selectedText)
    //           }
    //           );
    // }


    // const handleGetSources = async () => {
        

    //     setError(null)
    //     setParentLoading(true)

    //     await handleSelectionText();

    //     if (selectedText === '') {
    //         setError('Please select text from the document first.')
    //         setParentLoading(false)
    //         return
    //     }

    //     try {
            
    //         const tok = await getOrgAccessToken()
    //         const sources = await getSources(
    //             setParentLoading,
    //             setError,
    //             documentId,
    //             selectedText,
    //             '', // section_name is empty as per the provided function
    //             tok
    //         )
    //         if (sources) {
    //             setSourceResults(sources)
    //         }

            
    //     } catch (error) {
    //         console.error('Error getting sources:', error)
    //         setError('Failed to retrieve sources. Please try again.')
    //     } finally {
    //         setParentLoading(false)
    //     }
    // }

    const handleSelectionText = async () => {
        return new Promise((resolve, reject) => {
            var docEditor = window.DocEditor.instances['docxEditor'];
            connector = docEditor.createConnector();
            connector.executeMethod("GetSelectedText", [], function (selectedText) {
                console.log("Selected Text: ", selectedText);
                setSelectedText(selectedText);
                resolve(selectedText);
            });
        });
    };
    
    const handleGetSources = async () => {
        setError(null);
        setParentLoading(true);
    
        try {
            const selectedText = await handleSelectionText();
    
            if (!selectedText) {
                console.log('error in selected text');
                setParentLoading(false);
                alert('Please select text from the document first.');
                setError('Please select text from the document first.');

                return;
            }

    
            // const tok = await getOrgAccessToken();
            // const sources = await getSources(
            //     setParentLoading,
            //     setError,
            //     documentId,
            //     selectedText,
            //     '', // section_name is empty as per the provided function
            //     tok
            // );
    
            // if (sources) {
            //     setSourceResults(sources);
            // }
        } catch (error) {
            console.error('Error getting sources:', error);
            setError('Failed to retrieve sources. Please try again.');
        } finally {
            setParentLoading(false);
        }
    };

    const formatSourceTitle = (source) => {
        return source.split('/').pop()
    }

    const handleSourceClick = (source) => {
        setSelectedSource(source)
        setDrawerOpen(true)
    }

    // const renderSourceCards = () => (
    //     <Grid container spacing={1}>
    //         {sourceResults.map((source, index) => (
    //             <Grid item xs={12} sm={6} md={4} key={index}>
    //                 <Card
    //                     onClick={() => handleSourceClick(source)}
    //                     sx={{
    //                         cursor: 'pointer',
    //                         '&:hover': { boxShadow: 3 },
    //                         height: '100%',
    //                         display: 'flex',
    //                         flexDirection: 'column',
    //                     }}
    //                 >
    //                     <Tooltip
    //                         title={formatSourceTitle(source.source)}
    //                         placement="top"
    //                     >
    //                         <CardContent sx={{ flexGrow: 1, p: 1 }}>
    //                             <Typography
    //                                 variant="subtitle2"
    //                                 component="div"
    //                                 noWrap
    //                             >
    //                                 {formatSourceTitle(source.source)}
    //                             </Typography>
    //                             <Typography
    //                                 variant="body2"
    //                                 color="text.secondary"
    //                                 sx={{
    //                                     overflow: 'hidden',
    //                                     textOverflow: 'ellipsis',
    //                                     display: '-webkit-box',
    //                                     WebkitLineClamp: 3,
    //                                     WebkitBoxOrient: 'vertical',
    //                                 }}
    //                             >
    //                                 {source.text}
    //                             </Typography>
    //                         </CardContent>
    //                     </Tooltip>
    //                 </Card>
    //             </Grid>
    //         ))}
    //     </Grid>
    // )

    const renderSourceDrawer = () => (
        <>
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                <Box sx={{ width: '35vw', p: 2 }}>
                    {selectedSource && (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mb: 2,
                                }}
                            >
                                <Typography variant="h6">
                                    {formatSourceTitle(selectedSource.source)}
                                </Typography>
                                <IconButton
                                    onClick={() => setDrawerOpen(false)}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Chip
                                label={`Page ${selectedSource.page_number}`}
                                sx={{ mb: 2 }}
                            />
                            <Box>
                                <Typography variant="subtitle2" gutterBottom>
                                    Snippet:
                                </Typography>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={20}
                                    value={selectedSource.text}
                                    variant="outlined"
                                    sx={{
                                        // width: '100%',
                                        // minHeight: '200px',
                                        // maxHeight: '50vh',
                                        // overflowY: 'auto',
                                        // // padding: '8px',
                                        marginBottom: '16px',
                                        // resize: 'vertical',
                                    }}
                                    slotProps={{
                                        input: {
                                            readOnly: true,
                                        },
                                    }}
                                />
                            </Box>
                            <Button
                                variant="contained"
                                href={selectedSource.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Go to Source
                            </Button>
                        </>
                    )}
                </Box>
            </Drawer>
        </>
    )

    return (
        <>
            <Accordion expanded={expanded} onChange={handleAccordionChange}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Regenerate Selection</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mb: 1,
                        }}
                    >
                        <Typography variant="subtitle1">
                            Modify Text
                        </Typography>
                        <Tooltip
                            title="Select text in the document and click 'Regenerate Selected Text' to regenerate that text. Add instructions or additional information in the box below to modify the text."
                            arrow
                        >
                            <IconButton
                                size="small"
                                sx={{ ml: 1, mt: 0, p: 0 }}
                            >
                                <InfoIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <TextField
                        fullWidth
                        multiline
                        rows={16}
                        label="Text Modification Instructions"
                        value={additionalInfo}
                        onChange={(e) =>
                            setAdditionalInfo(
                                e.target.value
                            )
                        }
                        variant="outlined"
                        sx={{ zIndex: 0}}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mb: 1,
                            mt: 2,
                        }}
                    >
                    <Typography variant="subtitle1">
                        Sources
                    </Typography>
                    <Tooltip
                            title="Select Manage Sources to add new sources for this selection"
                            arrow
                        >
                            <IconButton
                                size="small"
                                sx={{ ml: 1, mt: 0, p: 0 }}
                            >
                                <InfoIcon fontSize="small" />
                            </IconButton>
                    </Tooltip>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            mb: 2,
                        }}
                    >
                        {allSources.length > 0 ? (
                            allSources.map((source, index) => (
                                <Chip
                                    key={index}
                                    label={formatSourceTitle(source)}
                                    onDelete={
                                        newSources.includes(source)
                                            ? () => handleSourceRemove(source)
                                            : undefined
                                    }
                                    sx={{
                                        maxWidth: '100%',
                                        justifyContent: 'space-between',
                                    }}
                                />
                            ))
                        ) : (
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 1, ml: 1 }}>
                                No New Sources Added
                            </Typography>
                        )}
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                    <Button
                        disabled={isDemo ? true : false}
                        style={{}}
                        onClick={() => setIsSourceModalOpen(true)}
                    >
                        Manage Sources
                    </Button>
                    {isDemo && <Alert severity="info" sx={{ mb: 2 }}>
                        This button is used to add or remove sources for
                        the section. This feature has been disabled for
                        this sandbox.
                    </Alert>}
                    <Box display='flex' flexDirection='row' alignItems='center'>
                    <Button
                        onClick={ handleRegenerate}
                        variant="contained"
                        color="primary"
                        disabled={false}
                        style={{ marginTop: '16px' }}
                    >
                        Regenerate Selected Text
                    </Button>
                    <Tooltip
                        title="You can only regenerate text within one section or subsection at a time. If you need to regenerate text from multiple sections, please regenerate each section separately."
                        arrow
                    >
                        <IconButton
                            size="small"
                            sx={{ ml: 1, mt: 2, p: 0 }}
                        >
                            <InfoIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    </Box>

                    {error && (
                        console.log('error', error),
                        <Typography color="error" sx={{ mt: 2 }}>
                            {error}
                        </Typography>
                    )}
                    </Box>
                    
                    {/* {sourceResults.length > 0 && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Source Results:
                            </Typography>
                            {renderSourceCards()}
                        </Box>
                    )} */}
                </AccordionDetails>
            </Accordion>
            {/* {renderSourceDrawer()} */}

            <SourceSelectionModal
                open={isSourceModalOpen}
                handleClose={() => setIsSourceModalOpen(false)}
                onSourceSelect={handleSourceAdd}
                inputFiles={inputFiles}
                isLoadingInputFiles={loading}
                oldFiles={allSources}
                id={documentId}
                originalSources={originalSources}
            />
        </>
    )
}

export default GetSources
